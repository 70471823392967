// themeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { themes } from '../../assets/themes'; // Import your theme definitions
import { RootState } from '../store';

type ThemeName = keyof typeof themes; // 'elisyan' | 'sopher' | ...
type ThemeMode = 'light' | 'dark';

interface ThemeState {
  activeTheme: ThemeName;
  mode: ThemeMode;
  theme: typeof themes[ThemeName][ThemeMode];
}

const initialState: ThemeState = {
  activeTheme: 'elisyan', // Default theme
  mode: 'light', // Default mode
  theme: themes.elisyan.light, // Default theme and mode combination
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleMode: (state) => {
      state.mode = state.mode === 'light' ? 'dark' : 'light';
      state.theme = themes[state.activeTheme][state.mode];
    },
    setTheme: (state, action: PayloadAction<{ theme: ThemeName; mode: ThemeMode }>) => {
      state.activeTheme = action.payload.theme;
      state.mode = action.payload.mode;
      state.theme = themes[action.payload.theme][action.payload.mode];
    },
  },
});

export const { toggleMode, setTheme } = themeSlice.actions;
export const selectState = (state: RootState) => state.theme_slice;
export default themeSlice.reducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store'; // Import your store type
import { profileStyles } from '../assets/styles/profileStyles'; 
import useCustomStyles from '../hooks/useCustomStyles';
import { ReactComponent as MockProfile } from '../assets/images/profile_icon_sopher.svg';


const ProfileSection = () => {
  const { theme } = useSelector((state: RootState) => state.theme_slice);
  const { user } = useSelector((state: RootState) => state.auth_slice); // Get the user data from the auth slice
  const classes = useCustomStyles(profileStyles, theme);

  const profileUrl = user?.profile_url || undefined;
  const userName = user?.user_name || 'Anonymous';
  const userRole = user?.role || 'Guest';

  return (
    <div className={classes.profileSection}>
      <div className={classes.pictureContainer}> 
        {user?.profile_url ? 
        <img
            src={profileUrl}
            alt="Profile"
            className={classes.profileImage}
        /> : <MockProfile height={50} width={50}/>
        }
      </div> 
      <div className={classes.profileDetails}>
        <p className={classes.userName}>{userName}</p>
        <p className={classes.userRole}>({userRole})</p>
      </div>
    </div>
  );
};

export default ProfileSection;

import React from 'react';
import LoadingCercle from '../assets/images/loadingCercle.gif';
import useCustomStyles from '../hooks/useCustomStyles';
import {customStyles} from '../assets/styles/CustomButtonStyles'
//import { Theme} from '../types';
import { useSelector } from 'react-redux';
import { selectState } from '../store/slices/themeSlice';
  
// Define the props interface for the component
interface CustomButtonProps {
  title: string;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  name: string;
  loading: boolean;
  disabled?: boolean; // disabled is optional
}

const CustomButton: React.FC<CustomButtonProps> = ({
  title,
  handleClick,
  name,
  loading,
  disabled = false, // default value for disabled is false
}) => {
    const {theme} = useSelector(selectState);
    const classes = useCustomStyles(customStyles, theme);
  return (
    <button
      name={name}
      //style={loading? styles.loadingButton : styles.button}
      className={classes?.customButton}
      onClick={handleClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <img className="loadingIcon" src={LoadingCercle} alt="loadingGif" />
      ) : (
        title
      )}
    </button>
  );
};

export default CustomButton;

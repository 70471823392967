import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomSelect from '../components/CustomSelectInput';
import CustomButton from '../components/CustomButton';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/notificationForm';
import { selectTranslationState } from '../store/slices/languageSlice';
import { selectState } from '../store/slices/themeSlice';
import CustomInput from '../components/CustomInput';
import SearchableSelect from '../components/SearchableSelect';

const GenerateReferralCode = () => {
  //const dispatch = useDispatch();
  const { t } = useSelector(selectTranslationState);
  const { theme, activeTheme} = useSelector(selectState)
  const classes = useCustomStyles(customStyles, theme);

  const [codeType, setCodeType] = useState('sopher');
  const [email, setEmail] = useState('');
  const [validity, setValidity] = useState('');
  const [useLimit, setUseLimit] = useState(1);
  const [emailList] = useState(['email1@example.com', 'email2@example.com']); // Replace with your actual email list
  const [code, setCode] = useState('');


  
  const handleCodeTypeChange = (value: string) => {
    setCodeType(value);
    // Reset email and validity when changing code type
    setEmail('');
    setValidity('');
    setUseLimit(1);
  };

  const handleGenerateCode = () => {
    // Logic to generate the referral code based on the selected options
    const generatedCode = `CODE-${Math.random().toString(36).substr(2, 8).toUpperCase()}`;
    setCode(generatedCode);
    
    // Dispatch your code generation action here if needed
    // dispatch(generateReferralCode({ codeType, email, validity, useLimit }));
  };

  return (

      <div className={classes?.container}>
      <div className={classes?.notificationForm}>
        <h1 className={classes?.title}>{t[activeTheme].referralCodeScreen.form.title}</h1>
        <hr className={classes?.divider}></hr>
        <CustomSelect 
          name="codeType" 
          value={codeType} 
          options={['sopher', 'secondPartner', 'waitlist']} 
          onChange={(e)=>handleCodeTypeChange(e.target.value)} 
        />
        
        {codeType === 'waitlist' && (
          <SearchableSelect 
          options={emailList} 
          value={email} 
          onChange={setEmail} 
          placeholder="Search for an email..."
        />
        )}
        
        {(codeType === 'sopher' || codeType === 'secondPartner') && (
          <>
            <CustomInput 
              name='validity'
              type="date" 
              value={validity} 
              onChange={(e) => setValidity(e.target.value)} 
              placeholder="Select Validity" 
           
            />
            <CustomInput
              name='useLimit'
              type="number" 
              value={useLimit} 
              onChange={(e) => setUseLimit(parseInt(e.target.value))} 
              placeholder="Use Limit (1-500)"
            />
          </>
        )}

        <CustomButton
                  title="Generate Code"
                  name="generateReferralCode"
                  handleClick={handleGenerateCode} loading={false} />
        
        {code && <p>Your generated code: {code}</p>}
      </div>
      </div>
    
  );
};

export default GenerateReferralCode;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { ReactComponent as LightLogoElisyan } from '../assets/images/light_logo_elisyan.svg';
import { ReactComponent as DarkLogoElisyan } from '../assets/images/logo_dark_elisyan.svg';
import { ReactComponent as LightLogoSopher } from '../assets/images/light_logo_sopher.svg';
import { ReactComponent as DarkLogoSopher } from '../assets/images/dark_logo_sopher.svg';
import { ReactComponent as LightMenuElisyan } from '../assets/images/Menu_hamburguer_light_elisyan.svg';
import { ReactComponent as DarkMenuElisyan } from '../assets/images/Menu_hamburguer_dark_elisyan.svg';
import { ReactComponent as LightMenuSopher } from '../assets/images/Menu_hamburguer_light_sopher.svg';
import { ReactComponent as DarkMenuSopher } from '../assets/images/Menu_hamburguer_dark_sopher.svg';
//import { ReactComponent as HamburgerIcon } from '../assets/images/profile icon elisyan.svg'; // Add a hamburger icon SVG
//import { ReactComponent as ToggleDarkIcon } from '../assets/images/swap-off.svg';
//import { ReactComponent as ToggleLightIcon } from '../assets/images/swap-on.svg'; // Add a hamburger icon SVG
import { ReactComponent as LightToggleElisyan } from '../assets/images/light_toggle_elisyan.svg';
import { ReactComponent as DarkToggleElisyan } from '../assets/images/dark_toggle_elisyan.svg';
import { ReactComponent as LightToggleSopher } from '../assets/images/light_toggle_sopher.svg';
import { ReactComponent as DarkToggleSopher } from '../assets/images/dark_toggle_sopher.svg';
import '../assets/styles/index.css';
import '../assets/styles/index.css';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/header';
import { setTheme, toggleMode } from '../store/slices/themeSlice';
import { selectTranslationState } from '../store/slices/languageSlice';

function Header() {
  const { theme, activeTheme, mode } = useSelector((state: RootState) => state.theme_slice);
  const dispatch = useDispatch<AppDispatch>();
 // const navigate = useNavigate();
  //const { error, loading, isAuthenticated } = useSelector(selectState);
  const classes = useCustomStyles(customStyles, theme);
  const {t} = useSelector(selectTranslationState);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    console.log('toggleMenus', isMenuOpen)
    setIsMenuOpen(!isMenuOpen);
  };
  const getToggleIcon = () => {
    if (activeTheme === 'elisyan') {
      return mode === 'light' ? <LightToggleElisyan onClick={() =>dispatch(toggleMode())} width={45} height={45} /> : <DarkToggleElisyan onClick={() =>dispatch(toggleMode())} width={45} height={45} />;
    } else if (activeTheme === 'sopher') {
      return mode === 'light' ? <LightToggleSopher onClick={() =>dispatch(toggleMode())} width={45} height={45} /> : <DarkToggleSopher onClick={() =>dispatch(toggleMode())} width={45} height={45} />;
    }
    return null; // or a default logo if you want to have one
  };
  const getMenuIcon = () => {
    if (activeTheme === 'elisyan') {
      return mode === 'light' ? <LightMenuElisyan width={45} height={45} /> : <DarkMenuElisyan width={45} height={45} />;
    } else if (activeTheme === 'sopher') {
      return mode === 'light' ? <LightMenuSopher width={45} height={45} /> : <DarkMenuSopher width={45} height={45} />;
    }
    return null; // or a default logo if you want to have one
  };
 // Function to determine the correct logo
 const getLogo = () => {
  if (activeTheme === 'elisyan') {
    return mode === 'light' ? <LightLogoElisyan width={150} height={55} /> : <DarkLogoElisyan width={150} height={55} />;
  } else if (activeTheme === 'sopher') {
    return mode === 'light' ? <LightLogoSopher width={150} height={50} /> : <DarkLogoSopher width={150} height={50} />;
  }
  return null; // or a default logo if you want to have one
};
  return (
    <div className={classes?.container}>
      <div className={classes?.logo}>
      {getLogo()} {/* Dynamically rendered logo */}
       {/* <div className={classes?.logoText}>
          <strong>{t[activeTheme].header.logo.text1}</strong><strong>{t[activeTheme].header.logo.text2}</strong>
        </div>*/}
      </div>
      <div className={classes?.hamburgerIcon} onClick={()=>toggleMenu()}>
        {getMenuIcon()}
      </div>
      <ul onClick={()=>toggleMenu()} className={`${classes?.navigationList} ${isMenuOpen ? classes?.navigationListOpen : ''}`}>
       {/*<li><NavLink to="/sendNotification" className={({ isActive }) =>
        `${classes?.link} ${isActive ? classes?.activeLink : ''}`
      }> {t[activeTheme].header.navigationList.link1}</NavLink></li>
        <li> <NavLink to="/" className={({ isActive }) =>
        `${classes?.link} ${isActive ? classes?.activeLink : ''}`
      }>{t[activeTheme].header.navigationList.link2}</NavLink></li>
         <li><NavLink to="/generateReferalCode" className={({ isActive }) =>
        `${classes?.link} ${isActive ? classes?.activeLink : ''}`
      }>{t[activeTheme].header.navigationList.link3}</NavLink></li>*/}
       <li><strong className={classes?.logout}>{t[activeTheme].header.logout.title}</strong></li>
       <li> {getToggleIcon()}</li>
       <li> {activeTheme == 'elisyan'? <LightToggleElisyan width={45} height={45} onClick={() =>dispatch(setTheme({ theme: 'sopher', mode: 'light'}))} /> : <DarkToggleSopher width={45} height={45} onClick={() =>dispatch(setTheme({ theme: 'elisyan', mode: 'light'}))} />}</li>
      </ul>
    </div>
  );
}

export default Header;

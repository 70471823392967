import React from 'react';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/customInputStyles';
import { useSelector } from 'react-redux';
import { selectState } from '../store/slices/themeSlice';

// Define the props interface for the component
interface CustomInputProps {
  name: string;
  value: string | number;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string; // Optional, default is "text"
  disabled?: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({
  name,
  value,
  placeholder = '',
  onChange,
  type = 'text',
  disabled = false,
}) => {
  const { theme } = useSelector(selectState);
  const classes = useCustomStyles(customStyles, theme);

  return (
    <input
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      type={type}
      className={classes?.customInput}
      disabled={disabled}
    />
  );
};

export default CustomInput;

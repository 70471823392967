import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../store/store';
import { login } from '../store/actions/authActions';
import { selectState } from '../store/slices/authSlice';
import { ReactComponent as LightLogoElisyan } from '../assets/images/light_big_logo_elisyan.svg';
import '../assets/styles/index.css';
import CustomButton from '../components/CustomButton';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/login';
import CustomInput from '../components/CustomInput';
import { setTheme } from '../store/slices/themeSlice';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const {theme} = useSelector((state: RootState) => state.theme_slice);
  const {user} = useSelector((state: RootState) => state.auth_slice)
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { error, loading, isAuthenticated } = useSelector(selectState);
  const classes = useCustomStyles(customStyles, theme);
   console.log('themefrom login ', theme.colorSchema.inputs.primary.background)
  const validate = () => {
    let isValid = true;

    if (!email) {
      setEmailError('Email is required.');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email.');
      isValid = false;
    } else {
      setEmailError(null);
    }

    if (!password) {
      setPasswordError('Password is required.');
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters.');
      isValid = false;
    } else {
      setPasswordError(null);
    }

    return isValid;
  };

  const handleLogin = async () => {
    if (validate()) {
      await dispatch(login({ email, password }));
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
        console.log('isauthenticated', isAuthenticated);
        dispatch(setTheme({ theme: user?.role == 'admin'? 'elisyan' : 'sopher', mode: 'light'}));
        navigate('/sendNotification');
      }
  }, [isAuthenticated])
  

  return (
    <div className={classes?.container}>
      <LightLogoElisyan  width={200} height={200}/>
      <h2 className={classes?.title}>Take Back The Control Of Your Wealth</h2>
     
      <div>

      <CustomInput
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email" name={''} 
        />
      {emailError && <p className={classes?.error}>{emailError}</p>}
      <CustomInput
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password" name={''}       
        />
      {passwordError && <p className={classes?.error}>{passwordError}</p>}
      <CustomButton
        title={loading ? 'Logging in...' : 'Login'}
        name='login'
        handleClick={handleLogin}
        disabled={false}
        loading = {false}
      />

      {error && <p className={classes?.error}>{error.toString()}</p>}
      </div>
    </div>
  );
}

export default Login;

import { Theme } from '../../types';
import { CSSObject } from '@emotion/react';

export const layoutStyles = (theme: Theme): { [key: string]: Record<string, CSSObject | string | number> } => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: theme.colorSchema.colors.secondaryBackground,
  },
  header: {
    height: '60px',
    width: '100%',
    backgroundColor: theme.colorSchema.colors.primary,
    zIndex: 1000,
  },
  sidebar: {
    width: '220px',
    height: 'calc(100vh - 60px)', // Full height minus the header
    position: 'fixed',
    top: '60px',
    left: 0,
    backgroundColor: theme.colorSchema.colors.mainBackground,
    boxShadow: `0px 2px 10px rgba(0, 0, 0, 0.1)`,
    padding: '20px',
  },
  mainContent: {
    marginLeft: '250px', // Leave space for sidebar
    padding: '20px',
    flexGrow: 1,
    overflowY: 'auto',
  },
});

import { Theme } from '../../types';
import { CSSObject } from '@emotion/react';

export const sideNavigationStyles = (theme: Theme): { [key: string]: Record<string, CSSObject | string | number> } => ({
  container: {
    width: '100%',
    backgroundColor: theme.colorSchema.colors.mainBackground,
    boxShadow: `0px 2px 5px ${theme.colorSchema.colors.mainText}`,
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    transition: 'transform 0.3s ease',
    transform: 'translateX(-100%)', // Hidden by default
  },
  containerOpen: {
    transform: 'translateX(0)', // Slide in when menu is open
  },
  hamburgerIcon: {
    cursor: 'pointer',
    marginBottom: '20px',
  },
  navigationList: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    padding: 0,
    marginTop: '2vh',
    width: '100%',
  },
  listItem: { 
    margin: '1vh 0px',
    width: '100%',
    padding: '10px 0px',
    '&:hover': {
      backgroundColor: theme.colorSchema.colors.secondaryBackground,
      color: theme.colorSchema.colors.secondaryText,
    },
  },
  link: {
    cursor: 'pointer',
    
    textDecoration: 'none',
    color: theme.colorSchema.colors.mainText,
    width: '100%',
    textAlign: 'left',
    borderRadius: '5px',
    //margin: '20px',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    
    '&:active': {
      backgroundColor: theme.colorSchema.colors.primary,
      textDecoration: 'underline',
    },
  },
  activeLink: {
    color: theme.colorSchema.colors.primary,
    textDecoration: 'underline',
  },
  logout: {
    marginTop: 'auto', // Pushes logout to the bottom
    color: theme.colorSchema.colors.mainText,
    paddingLeft: '20px',
  },
});

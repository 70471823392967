import React from 'react';
import './App.css';
import SendNotification from './components/SendNotification';
import Login from './views/login';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectState } from './store/slices/authSlice';
import GenerateReferralCode from './views/referalCode';
import AddToWaitlist from './components/AddToWaitlist';
import Dashboard from './views/dashboard';
import Layout from './views/layout';

interface ProtectedRouteProps {
  element: JSX.Element;
  isAuthenticated: boolean;
}

function ProtectedRoute({ element, isAuthenticated }: ProtectedRouteProps) {
  return isAuthenticated ? element : <Navigate to="/login" />;
}

const App = () => {
  const { isAuthenticated } = useSelector(selectState);
  

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Dashboard /></Layout>} />
        <Route path="/addToWaitlist" element={<Layout><AddToWaitlist /></Layout>} />
        <Route path="/login" element={<Login />} />
        <Route path="/sendNotification" element={<ProtectedRoute element={<Layout><SendNotification /></Layout>} isAuthenticated={isAuthenticated} />} />
        <Route path="/generateReferalCode" element={<ProtectedRoute element={<Layout><GenerateReferralCode /></Layout>} isAuthenticated={isAuthenticated} />} />
      </Routes>
  </Router>
  );
}

export default App;

/** @jsxImportSource @emotion/react */
import React from 'react';
import SideNavigation from '../components/sideNavigation';
import Header from '../components/Header';
import { layoutStyles } from '../assets/styles/layoutStyles'; // Style file for layout
import { RootState } from '../store/store';
import useCustomStyles from '../hooks/useCustomStyles';
import { useSelector } from 'react-redux';

const Layout = ({ children }: { children: React.ReactNode }) => {
    const { theme } = useSelector((state: RootState) => state.theme_slice);
    // const dispatch = useDispatch<AppDispatch>();
    
     const classes = useCustomStyles(layoutStyles, theme);
   
  return (
    <div className={classes.container}>
      {/* Include the header */}
      <header className={classes.header}>
        <Header />
      </header>
      
      {/* Include the side navigation */}
      <aside className={classes.sidebar}>
        <SideNavigation />
      </aside>
      
      {/* Main content area */}
      <main className={classes.mainContent}>
        {children}
      </main>
    </div>
  );
};

export default Layout;

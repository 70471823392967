import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from '../store/store';

import '../assets/styles/index.css';
import '../assets/styles/index.css';
import useCustomStyles from '../hooks/useCustomStyles';
import { sideNavigationStyles } from '../assets/styles/sideNavigation';
//import { setTheme, toggleMode } from '../store/slices/themeSlice';
import { selectTranslationState } from '../store/slices/languageSlice';
import ProfileSection from './Profile';

function SideNavigation() {
  const { theme, activeTheme } = useSelector((state: RootState) => state.theme_slice);
  //const dispatch = useDispatch<AppDispatch>();
 // const navigate = useNavigate();
  //const { error, loading, isAuthenticated } = useSelector(selectState);
  const classes = useCustomStyles(sideNavigationStyles, theme);
  const {t} = useSelector(selectTranslationState);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    console.log('toggleMenus', isMenuOpen)
    setIsMenuOpen(!isMenuOpen);
  };
  
 
  return (
    <div>{/*className={classes?.container}*/}
      <ul onClick={()=>toggleMenu()} className={`${classes?.navigationList} ${isMenuOpen ? classes?.navigationListOpen : ''}`}>
      <li className={classes.listItem}>
          <ProfileSection />
        </li>
      <li className={classes.listItem}>
          <NavLink to="/" className={({ isActive }) =>
            `${classes?.link} ${isActive ? classes?.activeLink : ''}`
          }> 
            {t[activeTheme].header.navigationList.link5}
          </NavLink>
        </li>

        <li className={classes.listItem}>
          <NavLink to="/sendNotification" className={({ isActive }) =>
            `${classes?.link} ${isActive ? classes?.activeLink : ''}`
          }> 
            {t[activeTheme].header.navigationList.link1}
          </NavLink>
        </li>
        
        <li className={classes.listItem}> 
          <NavLink to="/" className={({ isActive }) =>
            `${classes?.link} ${isActive ? classes?.activeLink : ''}`
          }>
            {t[activeTheme].header.navigationList.link2}
          </NavLink>
        </li>

        <li className={classes.listItem}> 
          <NavLink to="/" className={({ isActive }) =>
            `${classes?.link} ${isActive ? classes?.activeLink : ''}`
          }>
            {t[activeTheme].header.navigationList.link4}
          </NavLink>
        </li>

        <li className={classes.listItem}>
          <NavLink to="/generateReferalCode" className={({ isActive }) =>
            `${classes?.link} ${isActive ? classes?.activeLink : ''}`
          }>
            {t[activeTheme].header.navigationList.link3}
          </NavLink>
        </li>

      </ul>
    </div>
  );
}

export default SideNavigation;

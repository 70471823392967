import React from 'react';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/customeSelectStyles';
import { useSelector } from 'react-redux';
import { selectState } from '../store/slices/themeSlice';

interface Option {
  value: string;
  label: string;
}

// Define the props interface for the component
interface CustomSelectProps {
  name: string;
  value: string;
  options: string[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  name,
  value,
  options,
  onChange,
  disabled = false,
}) => {
  const { theme } = useSelector(selectState);
  const classes = useCustomStyles(customStyles, theme);

  return (
    <select
      name={name}
      value={value}
      onChange={onChange}
      className={classes?.customSelect}
      disabled={disabled}
    >
      {options.map((option,index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default CustomSelect;


import { createSlice } from '@reduxjs/toolkit'; //importing create slice
import { RootState } from '../store';
import { login, logout } from '../actions/authActions';
import { UserData } from '../actions/authActions';

// define initialState


type State = {
  user: UserData | null;
  loading: boolean;
  error: string | Error | null | undefined;
  isAuthenticated: boolean;
};

const initialState: State = {
    user: null,
    loading: false,
    error:  null,
    isAuthenticated: false
};

const authSlice = createSlice({
  name: 'auth_slice',
  initialState,
  reducers: {    
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.isAuthenticated = true;
        state.user = action.payload;
        console.log('login response ::', action.payload);
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      console.log('login error', action.payload)
      if (typeof action.payload === 'string'){
        state.error = action.payload;
      } else if(action.payload instanceof Error){
        state.error = action.payload.message;
      }else{
        state.error = new Error('Unknown error occurred');
      }
    });
    builder.addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.isAuthenticated = false;
      });
  },
});

export const selectState = (state: RootState) => state.auth_slice;

export default authSlice.reducer;
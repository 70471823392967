import { Theme } from '../../types';
import { CSSObject } from '@emotion/react';

export const profileStyles = (theme: Theme): { [key: string]: Record<string, CSSObject | string | number> } => ({
   
    profileSection: {
      display: 'flex',
      alignItems: 'center',
     // marginBottom: '2vh',
    },
    profileImage: {
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      objectFit: 'cover',
      
      
    },
    pictureContainer: {
     // marginBottom: '10px',
      marginRight: '10px',
      width: '65px',
      height: '65px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      border: `2px solid ${theme.colorSchema.colors.thirdText}`,
      boxShadow: `0px 2px 5px ${theme.colorSchema.colors.mainText}`,
    },
    profileDetails: {
      //textAlign: 'center',
      color: theme.colorSchema.colors.mainText,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    userName: {
      fontWeight: 'bold',
      fontSize: '1rem',
      margin: 0
    },
    userRole: {
      fontStyle: 'italic',
      color: theme.colorSchema.colors.secondaryText,
      margin: 0
    },
 
  });
  
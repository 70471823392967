import { Theme } from '../../types';
import { CSSObject } from '@emotion/react';

export const customStyles = (theme: Theme): {[key: string]: Record<string, CSSObject | string |number>} => ({
  customInput: {
    boxSizing: 'border-box',
    width: '100%',
    padding: '10px',
    borderRadius: '15px',
    fontSize: '1rem',
    marginBottom: '10px',
    fontWeight: 550,
    border: `2px solid ${theme.colorSchema.inputs.primary.outline}`,
    backgroundColor: theme.colorSchema.inputs.primary.background[0],
    boxShadow: `0px 0px 10px 0px ${theme.colorSchema.colors.mainText}`,
    color: theme.colorSchema.inputs.primary.text,
    transition: 'border-color 0.3s ease',
    '&:focus': {
      borderColor: theme.colorSchema.inputs.primary.background[1],
      outline: 'none',
    },
    '&:disabled': {
      opacity:0.5,
      cursor: 'not-allowed',
    },
    // Custom Placeholder Styling
    '&::placeholder': {
      color: theme.colorSchema.inputs.primary.text,
      opacity: 1, 
    },
  },
});

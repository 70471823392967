import { Theme } from '../../types';
import { CSSObject } from '@emotion/react';

export const customStyles = (theme: Theme): { [key: string]: Record<string, CSSObject | string | number> } => ({
  container: {
    //width: '100%',
    
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '10vh',
    padding: '0 20px',
   // backgroundColor: theme.colorSchema.colors.mainBackground,
  },
  title: {
    color: theme.colorSchema.colors.mainText,
    textAlign: 'center',
    fontWeight: 400,
    margin: 0
  },
  notificationForm: {
    maxWidth: '600px',
    minWidth: '320px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    width: '100%',
   // border: '1px solid #ddd',
   // borderRadius: '5px',
    boxSizing: 'border-box',
  },
  divider: {
    marginBottom: '2vh',
    width: '10%',
    height: '4px',
    backgroundColor: theme.colorSchema.colors.secondaryText,
    border: 'none',
    borderRadius: '5px',
  },
  notificationFormH1: {
    marginBottom: 0,
    fontWeight: 700,
  },
  notificationTextarea: {
    boxShadow: `0px 0px 10px 0px ${theme.colorSchema.colors.mainText}`,
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '600px',
    padding: '20px',
    marginBottom: '10px',
    border: `2px solid ${theme.colorSchema.buttons.primary.background[1]}`,
    borderRadius: '10px',
    backgroundColor: 'white',
    color: theme.colorSchema.inputs.primary.text,
    fontSize: '1rem',
    minHeight: '200px',
    resize: 'vertical',
    '&:focus': {
      borderColor: theme.colorSchema.inputs.primary.background[1],
      outline: 'none',
    },
    '&:disabled': {
      opacity:0.5,
      color: theme.colorSchema.buttons.primary.text,
      cursor: 'not-allowed',
    },
   // Custom Scrollbar Styling
   '&::-webkit-scrollbar': {
    width: '8px', // Width of the scrollbar
    //backgroundColor: '#f0f0f0', // Background color of the scrollbar track
  },
  '&::-webkit-scrollbar-track': {
    //backgroundColor: '#f0f0f0', // Color of the scrollbar track
    borderRadius: '10px', // Rounded corners for the track
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.colorSchema.buttons.primary.background[1], // Color of the scrollbar thumb
    borderRadius: '10px', // Rounded corners for the thumb
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555', // Thumb color on hover
  },
  // Firefox specific scrollbar styling
  scrollbarWidth: 'thin', // Make the scrollbar thinner
  scrollbarColor: `${theme.colorSchema.buttons.primary.background[0] } ${theme.colorSchema.buttons.primary.background[1]}`, // Thumb and track color
  },
  error: {
    color: 'red',
  },
  '@media (max-width: 768px)': {
    container: {
      maxWidth: '80%',
      marginTop: '8vh',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    divider: {
      width: '15%',
    },
    button: {
      padding: '12px 16px',
      fontSize: '0.9rem',
    },
    loadingButton: {
      padding: '12px 16px',
      fontSize: '0.9rem',
    },
    notificationFormH1: {
      fontSize: '2rem',
    },
  },
  '@media (max-width: 480px)': {
    container: {
      maxWidth: '90%',
      marginTop: '6vh',
    },
    button: {
      padding: '10px 14px',
      fontSize: '0.8rem',
    },
    loadingButton: {
      padding: '10px 14px',
      fontSize: '0.8rem',
    },
    notificationFormH1: {
      fontSize: '1.8rem',
    },
  },
});

import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth, firestore } from "../../Firebase";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { doc, getDoc } from 'firebase/firestore';

  interface loginArgs {
    email: string;
    password: string;
  }
  
export  interface UserData {
    id: string;
    email: string;
    role: string;
    user_name: string;
    //user: UserCredential;
    token: string;
    profile_url: string | null;
  }
  
  export const login = createAsyncThunk<UserData | undefined, loginArgs>(
    'auth_slice/login',
    async ({ email, password }, thunkAPI) => {
      try {
        // Sign in the user with Firebase Auth
        const response = await signInWithEmailAndPassword(auth, email, password);
  
        // Reference to the Firestore document for the user
        const userDocRef = doc(firestore, 'users', response.user.uid);
  
        // Fetch the Firestore document data
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          // Retrieve the custom attributes from Firestore
          const userData = userDoc.data();
          const role = userData?.role || ''; // Default to empty string if role is missing
          const user_name = userData?.user_name || ''; // Default to empty string if user_name is missing
  
          // Combine the Firebase user data with custom Firestore attributes
          const combinedUserData: UserData = {
            id: response.user.uid,
            email: response.user.email || '', // You can handle undefined email if needed
            role,
            user_name,
            //user: response, // Include the original user data from Firebase
            token: response.user.refreshToken,
            profile_url: response.user.photoURL
          };
  
          console.log('User custom attributes:', userData);
          return combinedUserData;
        } else {
          console.error('No user document found in Firestore.');
          return thunkAPI.rejectWithValue('User document not found, Please ask admin to assign you a role');
        }
      } catch (e) {
        console.log('-------------- Error to login' + e);
        return thunkAPI.rejectWithValue(e);
      }
    }
  );


export const logout = createAsyncThunk('auth/logout', async () => {
    await signOut(auth);
  });


 

  
  
import { Theme } from '../../types';
import { CSSObject } from '@emotion/react';

export const customStyles = (theme: Theme): {[key: string]: Record<string, CSSObject | string |number>} => ({
  customSelect: {
    boxSizing: 'border-box',
    width: '100%',
    padding: '10px',
    borderRadius: '15px',
    fontSize: '1rem',
    fontWeight: 550,
    marginBottom: '10px',
    border: `2px solid ${theme.colorSchema.buttons.primary.background[1]}`,
    boxShadow: `0px 0px 10px 0px ${theme.colorSchema.colors.mainText}`,
    backgroundColor: theme.colorSchema.colors.inputBackground,
    color: theme.colorSchema.inputs.primary.text,
    transition: 'border-color 0.3s ease',
    '&:focus': {
      borderColor: theme.colorSchema.colors.primary,
      outline: 'none',
    },
    '&:disabled': {
      backgroundColor: theme.colorSchema.colors.secondaryBackground,
      color: theme.colorSchema.colors.secondaryText,
      cursor: 'not-allowed',
    },
  },
});
